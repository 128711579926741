import React from "react";
import { useTranslation } from 'react-i18next';

const CopyRight = () => {
    const { t } = useTranslation();

    return (
        <div className="footer__containerCopyRight">
            <span className="footer__copyRight">
                © {t("GC Prom Prof Reserve")} 2024.
            </span>
            <span className="footer__copyRight">
            {t("All rights reserved.")}
            </span>
        </div>
    )
}

export default CopyRight;
