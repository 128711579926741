
const data = [
    {
        id: 1,
        lang: "RU",
    },
    {
        id: 2,
        lang: "EN",
    },
    {
        id: 3,
        lang: "KZ",
    }
]

export default data;



