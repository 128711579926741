import React from "react";
import TitlePageFormApplication from "./TitlePageFormApplication";
import SecondTitleFormApplication from "./SecondTitleFormApplication";
import Form from "./Form/index.jsx";
import IconsOfContacts from "../FormApplication/IconsOfContact";
import { useTranslation } from 'react-i18next';

const FormApplication = () => {
    // Хук библиотеки i18n меняет язык текста, записанного в public/locales/EN
    const { t } = useTranslation();
    return (
     // По Id производится переход к блоку при нажатии ссылок элементов Breadcrumbs, расположенных в блоках Header и Footer
     <div id="formApplication" className="formApplication">
        <div className="formApplication__container">
            <div className="formApplication__title">
                <TitlePageFormApplication />
                <SecondTitleFormApplication />
                <IconsOfContacts />
            </div>
            <div className="formApplication__containerImage">
                <div className="formApplication__image">
                    <img className="formApplication__image_1440" width={590} src="./images/coupleBuilders_png.png" alt="фото рабочих"></img>
                    <img className="formApplication__image_1024" width={412} src="./images/coupleBuilders_png.png" alt="фото рабочих"></img>
                </div>
            </div>
            <div className="formApplication__image_430">
                <img className="formApplication__imageCoupleBuilders_430" src="./images/coupleBuilders_png.png" alt="фото рабочих"></img>
            </div>
            <div className="formApplication__containerView">
                <Form />
            </div>
        </div>
        
        <div className="formApplication__containerView_430">
            <div className="formApplication__container_430">
                <div className="formApplication__formContainer_430">
                    <span className="formApplication__title_430">
                    {t("Application430")}
                    <br></br>
                    {t("Application430_2")}
                    </span>
                    <span className="formApplication__secondTitle_430">
                    {t("Application430_3")}
                    <br></br>
                    {t("Application430_4")}
                    </span>
                </div>
                <Form />
            </div>
        </div>
     </div>
    )
}

export default FormApplication;