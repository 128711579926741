import React from "react";
import { useTranslation } from 'react-i18next';

const Itn = () => {
    const { t } = useTranslation();

    return ( 
        <>
        <div className="footer__navContainer_hide footer__sidePadding footer__workingHours">
            <span className="footer__titleText">
            {t("TIN")}
            </span>
            <div className="footer__nav">
                <span className="footer__text_430">
                6453173644
                </span>
            </div>
        </div>
        </>
                
    )
}

export default Itn;