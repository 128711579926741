import React from "react";

const Logo = () => {
    return ( 
        <>
        <div className="footer__logo">
            <img width={107} height={41} src="./images/logoFooter_svg.svg" alt="логотип"></img>
        </div>
        <div className="footer__logo_430">
            <img width={77} height={30} src="./images/logoFooter_svg.svg" alt="логотип"></img>
        </div>
        </>
                
    )
}

export default Logo;