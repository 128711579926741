import React from "react";
import ButtonOfContact from "./ButtonOfContact";
import ButtonOfTitle from "./ButtonOfTitle";

const ButtonOfMainView = () => {
    return (
        <>
        <div className="mainView__containerButton">
            <ButtonOfTitle />
            <a href="tel:+79027100074">
                <img className="mainView__image_430px" height={50} src="./images/call.svg" alt=""></img>
            </a>
            <div className="mainView__containerButton_directionOfContact">
                <ButtonOfContact />
            </div>
        </div>
        </>
        
    )

}

export default ButtonOfMainView;