import React from "react";
import { useTranslation } from 'react-i18next';

const Saving = () => {
    const { t } = useTranslation();
    return (
        <>
        <div className="benefits__saving">
            <div className="benefits__content">
                <img src="./images/benefits01.svg" alt=""/>
                <span>
                {t("VAT refund")}
                </span>
           </div>
        </div> 
          <div className="benefits__saving">
            <div className="benefits__content">
                <img src="./images/benefits02.svg" alt=""/>
                <span>
                {t("Reduced costs and burden on accounting and HR departments")}
                </span>
           </div>
        </div> 
        <div className="benefits__saving">
            <div className="benefits__content">
                <img src="./images/benefits03.svg" alt=""/>
                <span>
                {t("Reduced advertising budget to attract staff")}
                </span>
           </div>
        </div> 
        <div className="benefits__saving">
            <div className="benefits__content">
                <img src="./images/benefits04.svg" alt=""/>
                <span>
                {t("Reducing the cost of compensation when reducing the number of staff")}
                </span>
           </div>
        </div> 
        <div className="benefits__saving">
            <div className="benefits__content">
                <img src="./images/benefits05.svg" alt=""/>
                <span>
                {t("Saving you time and money")}
                </span>
           </div>
        </div> 
        <div className="benefits__saving">
            <div className="benefits__content">
                <img src="./images/benefits06.svg" alt=""/>
                <span>
                {t("Base of specialists more than 1500 people")}
                </span>
           </div>
        </div> 
        <div className="benefits__saving">
            <div className="benefits__content">
                <img src="./images/benefits07.svg" alt=""/>
                <span>
                {t("Provision and replacement of specialists in the shortest possible time")}
                </span>
           </div>
        </div> 
        <div className="benefits__saving">
            <div className="benefits__content">
                <img src="./images/benefits08.svg" alt=""/>
                <span>
                {t("Our employee provides personal support and administration of specialists on an ongoing basis")}
                </span>
           </div>
        </div> 
        <div className="benefits__saving">
            <div className="benefits__content">
                <img src="./images/benefits09.svg" alt=""/>
                <span>
                {t("Flexible terms of cooperation")}
                </span>
           </div>
        </div> 
        </>  
    )

}

export default Saving;