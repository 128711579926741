import React from "react";
import { useTranslation } from 'react-i18next';

const Navigation = () => {
    const { t } = useTranslation();
    return (
        <div className="footer__navContainer">
            <span className="footer__titleText">
            {t("Navigation")}
            </span>
            <div className="footer__navContent">
                <div className="footer__nav">
                    <a href="#about" className="footer__text">
                    {t("About us inHeader")}
                    </a>
                    <a href="#services" className="footer__text">
                    {t("Services inHeader")}
                    </a>
                </div>
                <div className="footer__nav">
                    <a href="#benefits" className="footer__text">
                    {t("Advantages inHeader")}
                    </a>
                    <a href="#vacation" className="footer__text">
                    {t("Vacancies inHeader")}
                    </a>
                </div>
            </div> 
        </div>
    )
}

export default Navigation;
