import React from "react";
import TitlePageBenefits from "./TitlePageBenefits";
import Saving from "./Saving";

const Benefits = () => {
    return (
        <div id="benefits">
            <div className="benefits">
                <TitlePageBenefits />
            </div>
            <div className="benefits__container">
                <Saving />
            </div>
        </div>
    )
}
export default Benefits;