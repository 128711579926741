import React from "react";
import { useTranslation } from 'react-i18next';

const Button = ({ addComment }) => {
    // Хук библиотеки i18n меняет язык текста, записанного в public/locales/EN
    const { t } = useTranslation();

    return (
        <div>
            <div className="formApplication__buttonContainer">
                <button 
                onClick={(e) => {
                    addComment(e)}}
                type="submit" 
                className="formApplication__button"
                >
            {t("Send")}
                </button>
            </div> 
        </div>
    )
}

export default Button;