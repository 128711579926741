import React from "react";
import data from "./data";

const LanguageButons = ({ 
    activedLanguage, 
    handleClick,
    onChangeLanguage}) => {
    return (
        <div className="mainView__language">
            {data.map((item) => {
                const actived = item.id === activedLanguage;
                const activeClass = actived ? "mainView__languageButton_actived" : "";

                return (
                <button 
                    onClick={(e) => {
                    onChangeLanguage(item.lang); 
                    handleClick(e, item.id)}} 
                    className={`
                    ${activeClass}
                    mainView__languageButton
                     `}
                     key={item.id}
                     >
                    {`${item.lang}`}
                </button>
                );
            })}
        </div>   
         
        )

}

export default LanguageButons;