import React from "react";

const IconsOfContacts = () => {
    return (    
        <>
        <div className="footer__icons">
            <a href="https://wa.me/79027100074" target="_blank" rel="noopener">
                <img width={40} src="./images/Viber.svg" alt=""></img>
            </a>
            <a href="https://vk.com/public.gr_ppr" target="_blank" rel="noopener">
                <img width={40} src="./images/vk.svg" alt=""></img>
            </a>
            <a href="https://dzen.ru/id/655477b43a2cd66a591509d1" target="_blank" rel="noopener">
                <img width={40} src="./images/yandex-zen.svg" alt=""></img>
            </a>
        </div>
        <div className="footer__icons_430">
            <a href="https://vk.com/public.gr_ppr" target="_blank" rel="noopener">
                <img width={36} src="./images/vk.svg" alt=""></img>
            </a>
            <a href="https://wa.me/79027100074" target="_blank" rel="noopener">
                <img width={36} src="./images/Viber.svg" alt=""></img>
            </a>
            <a href="https://dzen.ru/id/655477b43a2cd66a591509d1" target="_blank" rel="noopener">
                <img width={36} src="./images/yandex-zen.svg" alt=""></img>
            </a>
        </div>
        </>   
    )
}

export default IconsOfContacts;