import React from "react";
import { useState, useEffect } from "react";
import Policy from "../Policy.jsx";
import Button from "./Button.jsx";
import Inputes from "./Inputes/Index.jsx";
import ThankYouPage from "../ThankYouPage.jsx";
  import { useTranslation } from 'react-i18next';

const Form = () => {
    // Хук библиотеки i18n меняет язык текста, записанного в public/locales/EN
    const { t } = useTranslation();

    const storage = window.localStorage;
    const [name, setName] = useState(storage.getItem('name'));
    const [contact, setContact] = useState(storage.getItem('contact'));
    const [email, setEmail] = useState(storage.getItem('email'));
    const [text, setText] = useState(storage.getItem('text'));

    const [isPressAddCommentBtn, setIsPressAddCommentBtn] = useState(false);

    const [nameValid, setNameValid] = useState(validate('name', name));
    const [contactValid, setContactValid] = useState(validate('contact', contact));
    const [emailValid, setEmailValid] = useState(validate('email', email));
    // const [textValid, setTextValid] = useState(validate('text', text));
    
    const changeName = (event) => {
        const value = event.target.value;
        storage.setItem('name', value);
        setName(value);
        setNameValid(validate('name', name));
    };
    const changeContact = (event) => {
        const value = event.target.value;
        storage.setItem('contact', value);
        setContact(value);
        setContactValid(validate('contact', value));
        if (contactValid.isValid) {
          emailValid.isValid = true
        } else if (emailValid.isValid) {
          contactValid.isValid = true
        } 
    };
    const changeEmail = (event) => {
      const value = event.target.value;
      storage.setItem('email', value);
      setEmail(value);
      setEmailValid(validate('email', value));
      if (emailValid.isValid) {
        contactValid.isValid = true
      } else if (contactValid.isValid) {
        emailValid.isValid = true
      }     
  };
    const changeText = (event) => {
        const value = event.target.value;
        storage.setItem('text', value);
        setText(value);
    };

    const [thankYouPage, setThankYouPage] = useState(false);
    const showThankYouPage = () => {
      setThankYouPage((prevThankYouPage) => 
      prevThankYouPage === false 
            ? true : false)}
            
    const addComment = (e) => {
        storage.clear();
        e.preventDefault(); 
        // setNameValid(validate('name', name));
        // setContactValid(validate('contact', contact));
        // setTextValid(validate('text', text));
        // setEmailValid(validate('email',email));
        // setIsPressAddCommentBtn(true);

        if (isPressAddCommentBtn
          &&
          nameValid.isValid 
          && (contactValid.isValid 
          || emailValid.isValid)
          ){
          fetch(
            `https://gkppr.ru/sandmail.php?name=${name}&phone=${contact}&email=${email}&text=${text}`,
            {
              method: "POST"
            }
            )
          .then (() => {
            // alert("форма отправилась");
            // console.log("ok")
            showThankYouPage();
          })
          .catch(err => {
            alert("форма не отправилась");
            // console.log("none")
            // showThankYouPage();
          })
        }
    }

    const handleFocus = (type) => {
        // eslint-disable-next-line default-case
        switch (type) {
          case 'name':
            if (
              !nameValid.isValid && isPressAddCommentBtn) {
              setName('');
              storage.setItem('name', '');
              setIsPressAddCommentBtn(false);
            }
             else {setIsPressAddCommentBtn(true)}
            break;
            case 'contact':
            if (!contactValid.isValid && isPressAddCommentBtn) {
              // setContact('+7');
              storage.setItem('contact', '');
              setIsPressAddCommentBtn(false);
            } else {setIsPressAddCommentBtn(true)}
            break;
            case 'email':
            if (!emailValid.isValid && isPressAddCommentBtn) {
              setEmail('');
              storage.setItem('email', '');
              setIsPressAddCommentBtn(false);
            } else {setIsPressAddCommentBtn(true)}
            break;
        }
      }
      useEffect(() => {
        if (isPressAddCommentBtn
           && nameValid.isValid 
           && contactValid.isValid 
           && emailValid.isValid 
           ) {
          setName('');
          setContact('');
          setEmail('');
          setText('');
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])
  
      function validate(type, value) {
          // eslint-disable-next-line default-case
          switch (type) {
            case 'name':
              // eslint-disable-next-line no-useless-escape
              const regexName = /[а-яА-Яa-zA-Z][а-яА-Яa-zA-Z0-9-_\.]{1,20}/
              const paternName = regexName.test(value)
              if (
                value === null
                ) {
                return { content: `${t("Please fill in the field")}`, isValid: false};
              } else if (
                !paternName
                ) {
                return { content: `${t("Please fill in the field")}`, isValid: false};  
              } else {
                return { content: value, isValid: true};
              }
              case 'contact':
              // eslint-disable-next-line no-useless-escape
              const regexTel = /^((8|\+374|\+994|\+995|\+375|\+7|\+380|\+38|\+996|\+998|\+993)[\- ]?)?\(?\d{3,5}\)?[\- ]?\d{1}[\- ]?\d{1}[\- ]?\d{1}[\- ]?\d{1}[\- ]?\d{1}(([\- ]?\d{1})?[\- ]?\d{1})?$/
              const paternTel = regexTel.test(value);
              if (
                value === null || 
                value === ""
                ) {
                return { content: `${t("Please fill in the field")}`, isValid: false};
              } else if (
                !paternTel
                ) {
                return { content: `${t("Please fill in the field")}`, isValid: false};
              } else {
                return { content: value, isValid: true};
              }
              case 'email':
              const regexEmail = /[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,4}/;
              if (
                value === null
                ) {
                return { content: `${t("Please fill in the field")}`, isValid: false}
              } else if (
                !regexEmail.test(value)
              ) {
                return { content: `${t("Check your email address")}`, isValid: false};
              } else {
                return { content: value, isValid: true};
              }
          }
        }

    return (
      <>
      {
        thankYouPage &&
        <ThankYouPage 
        showThankYouPage={showThankYouPage}
        />
      }
      <form 
        className="formApplication__formContainer">
        <Inputes
        name={name}
        contact={contact}
        email={email}
        text={text}
        isPressAddCommentBtn={isPressAddCommentBtn}
        nameValid={nameValid}
        contactValid={contactValid}
        emailValid={emailValid}
        changeName={changeName} 
        changeContact={changeContact} 
        changeEmail={changeEmail} 
        changeText={changeText}
        handleFocus={handleFocus}
        />
        <Button 
        addComment={addComment}
        // showForm={showForm} 
        />
        <Policy />
      </form>
      </>
    )
}

export default Form;
