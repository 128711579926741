import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Policy = () => {
    // Хук библиотеки i18n меняет язык текста, записанного в public/locales/EN
    const { t } = useTranslation();

    return (
        <div className="formApplication__policy">
            <span className="formApplication__textPolicy">
            {t("By clicking the button, you agree")}
            </span>
            <div className="formApplication__policyContainer">
                <span className="formApplication__textPolicy">
                {t("with")}
                </span>
                <Link to="/PersonalDataPolicy" className="formApplication__textPolicySecond">
                {t("personal data processing policy")}
                </Link>
            </div>
        </div>
    )
}

export default Policy;