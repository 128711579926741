import React from "react";
import { useTranslation } from 'react-i18next';

const Name = () => {
    const { t } = useTranslation();

    return ( 
        <>
        <div className="footer__navContainer_430">
            <span className="footer__titleText">
            {t("Name_main")}
            </span>
            <div className="footer__titleText_430">
                <span className="footer__text_withoutDecoration">
                {t("LLC Group of Companies")}
                </span>
                <span className="footer__text_withoutDecoration">
                {t("PromProfReserve")}
                </span>
            </div>
        </div>  
        </>
                
    )
}

export default Name;