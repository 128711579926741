import React from "react";
import { useTranslation } from 'react-i18next';

const Numbers = () => {
    const { t } = useTranslation();
    return (
        <div className="footer__navContainer">
            <span className="footer__titleText">
            {t("Contacts")}
            </span>
            <div className="footer__nav">
                {/* <span className="footer__text_withoutDecoration">
                +7 (902) 710-00-74
                </span> */}
                <a className="footer__text_withoutDecoration"
                href="tel:+79027100074">
                +7 (902) 710-00-74    
                </a>
                {/* <span className="footer__text_withoutDecoration"> */}
                {/* +7 (905) 324-60-02 */}
                {/* +7 (8452) 10-00-74
                </span> */}
                <a className="footer__text_withoutDecoration"
                href="tel:+78452100074">
                +7 (8452) 10-00-74    
                </a>
                {/* <span className="footer__text_withoutDecoration">
                info@gkppr.ru
                </span> */}
                <a className="footer__text_withoutDecoration" 
                href = "mailto: info@gkppr.ru">
                info@gkppr.ru</a>
            </div>
        </div>
    )
}

export default Numbers;
