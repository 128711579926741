import React from "react";
import { useTranslation } from 'react-i18next';

const Text = ({
    text,
    changeText, 
    handleFocus, 
    // isPressAddCommentBtn,
    // textValid,
}) => {
    const { t } = useTranslation();
    return (
        <div className="formApplication__containerTextarea">
            <textarea
            // autocomplete="off"
            name="text"
            className="formApplication__textarea"
            onInput={(e) => changeText(e)}
            onBlur={(e) => changeText(e)}
            // onChange={(e) => changeText(e)}
            onFocus={(e) => handleFocus("text")}
            value={text} 
            placeholder=
            {t("your message")}
            />
            {/* </textarea> */}
            {/* <div>  
                <div
                className={
                isPressAddCommentBtn
                ? (textValid.isValid 
                    ? "formApplication__error_okText" 
                    : "formApplication__error_noText") 
                : ""
                }
                >
                {isPressAddCommentBtn
                && (textValid && textValid.isValid ? "" : textValid.content)
                }
                </div>
            </div>  */}
        </div>
    )
}

export default Text;