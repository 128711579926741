import React, { useState } from "react";
import InfoApplication from "./InfoApplication";
import ButtonsOfApplication from "./Buttons/Index";
import KeywordsOfApplication from "./KeywordsOfApplication";
import { useTranslation } from 'react-i18next';

// import FormVidjetThankYouPage from "../FormApplication/FormVidjetThankYouPage.jsx";

const Application = () => {
    const { t } = useTranslation();

    const [activeClass, setActiveLClass] = useState("form-media_hide");
    const showForm = () => {
        setActiveLClass((prevActiveClass) => prevActiveClass === "form-media_hide" ? "form-media" : "form-media_hide")}
    
    const [activeFormVidjet, setActiveFormVidjet] = useState("formVidjet-media_hide");
    const showFormVidjet = () => {
        setActiveFormVidjet((prevActiveClass) => prevActiveClass === "formVidjet-media_hide" ? "formVidjet-media" : "formVidjet-media_hide")}
    
    return (
        <div className="application">
            <div className="application__container">
                <div>
                    <div className="apolication__container_1024">
                        <InfoApplication 
                        showForm={showForm} 
                        />
                        <div className="apolication__containerKeywords_1024">
                            <div className="application__keywords">
                                <span className="application__keyword">
                                {t("Handyman")}
                                    </span>
                                <span className="application__keyword">
                                {t("electrician")}
                                    </span>
                            </div>
                            <div className="application__keywords">
                                <span className="application__keyword">
                                {t("Driver B, C, D, E")}
                                    </span>
                                <span className="application__keyword">
                                {t("TT installer")}
                                    </span>
                                <span className="application__keyword">
                                {t("Objects")}
                                    </span>
                            </div>
                        </div>
                    </div>
                    <div className="apolication__containerBottom_1024">
                        <div className="apolication__containerButton_1024">
                            <ButtonsOfApplication 
                            showForm={showForm} 
                            activeClass={activeClass} 
                            showFormVidjet={showFormVidjet} 
                            activeFormVidjet={activeFormVidjet} 
                            />
                        </div>
                        <div className="apolication__containerKeywordsBottom_1024">
                            <div className="application__keywords_1024">
                                <span className="application__keyword">
                                {t("Engineering and technical worker")}
                                    </span>
                                <span className="application__keyword">
                                {t("Locksmith KCHPiA")}
                                    </span>
                            </div>
                            <div className="application__keywordsLast_1024">
                                <span className="application__keyword">
                                {t("ST and JBK installer")}
                                    </span>
                                <span className="application__keyword">
                                {t("concrete worker")}
                                    </span>
                                <span className="application__keyword">
                                {t("Fitter")}
                                    </span>
                                <span className="application__keyword">
                                {t("Welder RD/RAD")}
                                    </span>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div>
                    <KeywordsOfApplication />
                </div>
            </div>
        </div>
    )
}

export default Application;