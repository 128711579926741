import React from "react";
import { useTranslation } from 'react-i18next';

const TitlePageServices = () => {
    // Хук библиотеки i18n меняет язык текста, записанного в pblic/locales/EN
    const { t } = useTranslation();

    return (
        <h2 className="mainPrint__title">
            {t("SERVICES")}
        </h2>
    )

}

export default TitlePageServices;