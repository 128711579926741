import React from "react";
import Email from "./Email";
import Name from "./Name";
import Tel from "./Tel";
import Text from "./Text";
import { useTranslation } from 'react-i18next';

const Inputes = ({
    name, 
    contact,
    email,
    text,
    changeName, 
    changeContact, 
    changeEmail, 
    changeText, 
    handleFocus, 
    nameValid, 
    contactValid,
    emailValid,
    isPressAddCommentBtn,
}) => {
    const { t } = useTranslation();
    return (
        <div className="formApplication__input">
            <Name
            name={name} 
            changeName={changeName}
            handleFocus={handleFocus} 
            nameValid={nameValid} 
            isPressAddCommentBtn={isPressAddCommentBtn}
            />
            <div className="formApplication__containerOfInputes">
                <Tel 
                contact={contact}
                changeContact={changeContact} 
                handleFocus={handleFocus} 
                contactValid={contactValid}
                isPressAddCommentBtn={isPressAddCommentBtn}
                />
                <span className="formApplication__textContainer">
                {t("or")}
                    </span>
                <Email 
                email={email}
                changeEmail={changeEmail} 
                emailValid={emailValid}
                handleFocus={handleFocus} 
                isPressAddCommentBtn={isPressAddCommentBtn}
                />
            </div>
            <Text
            text={text}
            changeText={changeText}
            handleFocus={handleFocus} 
            isPressAddCommentBtn={isPressAddCommentBtn}
            />
        </div>
    )
}

export default Inputes;