import Rights from "./Rights";
import Navigation from "../Footer/Navigation.jsx";
import Logo from "./Logo";
import Numbers from "./Numbers";
import WorkingHours from "./WorkingHours";
import IconsOfContacts from "./IconsOfContact";
import CopyRight from "./CopyRight";
import Address from "./Address";
import Name from "./Name";
import Itn from "./Itn";
// import Requisites from "./Requisites";

const Footer = () => {
    return (
        <>
        <div id="contacts" className="footer__containerView_430">
            <div className="footer__containerView">
                <div className="footer__contactsContainer">
                    <Logo />
                    <div className="footer__contacts_430">
                        <Navigation />
                    </div>
                    <div className="footer__contacts_430">
                        <Address />
                        <Name />
                    </div>
                </div>
                <div className="footer__contactsContainer_1440">
                    <div className="footer__contacts">
                        <div className="footer__contacts_1024">
                            <Numbers />
                            <Itn />
                        </div>
                        <div className="footer__contacts_1024">
                            <WorkingHours />
                        </div>
                    </div>
                    <div className="footer__requisites">
                        <div className="footer__icons_430">
                            <IconsOfContacts />
                        </div>
                    </div>

                    <div className="footer__policy_430">
                        <Rights />
                    </div>
                </div>
            </div>
            <div>
                <div className="footer__containerViewForEdites_430">
                    <div>
                        <Logo />
                    </div>
                    <div className="footer__contactsContainerForEdites_430">
                        <div className="footer__contactsForEdites_430">
                            <Navigation />
                            <div className="footer__iconsForEdites_430">
                                <Numbers />
                                <IconsOfContacts />
                            </div>
                        </div>
                        <div>
                            <WorkingHours />
                        </div>
                        <div>
                            <Name />
                        </div>
                        <div>
                            <Address />
                        </div>
                        <div>
                            <Itn />
                        </div>
                    </div>
                    <div className="footer__iconsContainerForEdites_430">
                        <div className="footer__policy_430">
                            <Rights />
                        </div>
                    </div>
                </div>

                <div className="footer__rights">
                    <CopyRight />
                    <div className="footer__policy">
                        <Rights />
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Footer;
