import React from "react";
import { useState } from "react";
import { useTranslation } from 'react-i18next';

const ButtonOfContact = () => {
    // Хук библиотеки i18n меняет язык текста, записанного в pblic/locales/EN
    const { t } = useTranslation();

    // Производится замена названия файла "call"/"called"
    const [isHoverCallBtn, setisHoverCallBtn] = useState('call');

    // Функция onChangeIconCall изменяют цвет кнопки, производя замену состояния isHoverCallBtn при наведении курсора
    const onChangeIconCall = () => {
        setisHoverCallBtn("called")
    }
    // Функция onChangeIconCalled изменяют цвет кнопки, производя замену состояния isHoverCallBtn при покидании области разметки изображения
    const onChangeIconCalled = () => {
        setisHoverCallBtn("call")
    }

    return (
        <div
        onMouseEnter={() => onChangeIconCall()} 
        onMouseLeave={() => onChangeIconCalled()} 
        >
            <a className="services__buttonOfContact" href="tel:+79027100074">
            <img
            width={30.22} height={30.22} src={`./images/${isHoverCallBtn}.svg`} alt=""></img>
            {t("Contact")}
            </a>
        </div>
    )

}

export default ButtonOfContact;