import React from "react";
import { useTranslation } from 'react-i18next';

const LinksOfBradcrumbs = ( { showHeader, activeClassHeader} ) => {
    // Хук библиотеки i18n меняет язык текста, записанного в public/locales/EN
    const { t } = useTranslation();

    return (
        <>
        <div className="header__linksOfHeader">
            <a href="#about" className="header__links">
                {t("About us inHeader")}
            </a>
            <a href="#services" className="header__links">
            {t("Services inHeader")}
            </a>
            <a href="#benefits" className="header__links">
            {t("Advantages inHeader")}
            </a>
            <a href="#contacts" className="header__links">
            {t("Contacts inHeader")}
            </a>
        </div>

        <div className={activeClassHeader}>  
            <div className="formVidjet__background_430"></div>
                <div className="header__burgerHide_430px">
                        <div className="header__containerLinksOfHeader_430px">
                            <div className="header__linksOfHeader_430px">
                                <a onClick={showHeader} href="#about" className="header__links_430px">
                                {t("About us inHeader")}
                                </a>
                                <a onClick={showHeader} href="#services" className="header__links_430px">
                                {t("Services inHeader")}
                                </a>
                                <a onClick={showHeader} href="#benefits" className="header__links_430px">
                                {t("Advantages inHeader")}
                                </a>
                                <a onClick={showHeader} href="#vacation" className="header__links_430px">
                                {t("Vacancies inHeader")}
                                </a>
                                <a onClick={showHeader} href="#contacts" className="header__links_430px">
                                {t("Contacts inHeader")}
                                </a>
                            </div>
                            <div>
                                <div className="header__cross_430px"
                                onClick={showHeader}
                                >
                                    <img src="./images/cross.svg" alt=""></img>
                                </div>  
                            </div>
                        </div>
                <div className="header__icons_430">
                    <a onClick={showHeader} href="https://dzen.ru/id/655477b43a2cd66a591509d1" target="_blank" rel="noopener">
                        <img width={40} src="./images/yandex-zen.svg" alt=""></img>
                    </a>
                    <a onClick={showHeader} href="https://vk.com/public.gr_ppr" target="_blank" rel="noopener">
                        <img width={40} src="./images/vk.svg" alt=""></img>
                    </a>
                    <a onClick={showHeader} href="https://wa.me/79027100074" target="_blank" rel="noopener">
                        <img width={40} src="./images/Viber.svg" alt=""></img>
                    </a>
                </div>        
                <div className="header__containerLogoOfHeader_430px">
                    <div className="header__logoOfHeader_430">
                        <div>
                            <span className="header__titleOfLogo_430">
                            {t("LLC Group of Companies")}
                            <br></br>
                            {t("PromProfReserve")}
                            </span>
                        </div>
                        <div className="header__ContainerOfAccreditation_430">
                            <img src="./images/accreditation_430.svg" alt=""></img>
                            <span className="header__titleOfAccreditation_430">
                            {t("Accreditation by Rostrud")}
                            <br></br>№2-23/64
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
        
        
    )

}

export default LinksOfBradcrumbs;