import React from "react";

import TitlePageAbout from "./TitlePageAbout";
import InfoAbout from "./InfoAbout";
import InfoWithIcons from "./InfoWithIcons";

const About = () => {
    return (
        // По Id производится переход к блоку при нажатии ссылок элементов Breadcrumbs, расположенных в блоках Header и Footer
        <div id="about" className="about">
            <div className="about__containerTitle">
                <TitlePageAbout />
                <div className="about__container">
                    <InfoAbout />
                    <div className="about__containerIcons">
                        <InfoWithIcons />
                    </div>
                </div>
            </div>
            <div className="about__containerIcons_1024">
                <InfoWithIcons />
            </div>
        </div>
    )
}

export default About;