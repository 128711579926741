import React from "react";
import InfoAboutPartnership from "./InfoAboutPartnership";
import InfoAboutVacation from "./InfoAboutVacation";
import InfoAboutTurnkey from "./InfoAboutTurnkey";
import InfoAboutCareer from "./InfoAboutCareer";

const InfoWithIcons = () => {
    return (
        <>
        <div className="about__icons">
            <div className="about__iconsItems">
                <InfoAboutPartnership />
                <InfoAboutVacation />
            </div>
            <div className="about__iconsItems">
                <InfoAboutTurnkey />
                <InfoAboutCareer />
            </div>
        </div>
        <div className="about__icons_430">
            <div className="about__iconsItems">
                <InfoAboutPartnership />
                <InfoAboutTurnkey />
            </div>
            <div className="about__iconsItems">
                <InfoAboutVacation />
                <InfoAboutCareer />
            </div>
        </div>
        </>
        
    )
}

export default InfoWithIcons;