import React from "react";

const Treadmill = () => {
    return (
        <>
        <div className="treadmill_430px">
            <div className="treadmill__container_430px">
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
                <img className="item_320px" src="./images/PPR.svg" alt=""/>
                <img className="itemLogo_320px" src="./images/logo_item.svg" alt=""/>
            </div>
        </div>
        </>
    )
}

export default Treadmill;