import React from "react";
import Form from "./Form";

const FormVidjet = ({
    showFormVidjet,
    activeFormVidjet
}) => {
    return (
        <div className={activeFormVidjet}>
            <div className="formVidjet__background">
                <div className="formVidjet__container">
                    <div className="formVidjet__form">
                        <Form />
                    </div>
                    <div className="formVidjet__image">
                        <div className="formVidjet__cross">
                            <img
                            onClick={showFormVidjet}
                            src="./images/cross_white.svg" alt=""></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default FormVidjet;