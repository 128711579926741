import React from "react";

import Header from "../Header/Index";
import TitlePage from "./TitlePage";
import TitlePageSecond from "./TitlePageSecond";
import Treadmill from "./Treadmill";
import ButtonOfMainView from "./ButtonsOfMainView";

const MainView = () => {
    
    return (
        <>
            <div className="mainPrintTitle mainPrint">
                <Header />
                <TitlePage />
                <TitlePageSecond />
                <ButtonOfMainView />
                <Treadmill />
            </div>
            
            <div className="mainPrint430px">
                <div className="containerviewPort_430px">
                <Header />
                <TitlePage />
                {/* <TitlePageSecond /> */}
                </div>
                <Treadmill />
            </div>
        </>
        
    )

}

export default MainView;