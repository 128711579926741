import React from "react";
import FormApplicationMob from "../../FormApplication/FormApplicationMob";
import FormVidjet from "../../FormApplication/FormVidjet";
import { useTranslation } from 'react-i18next';
// import ButtonOfApplication from "./ButtonOfApplication";

const ButtonsOfApplication = ({
    showForm, 
    activeClass,
    showFormVidjet,
    activeFormVidjet,
}) => { 
    const { t } = useTranslation();
    return (
        <>
        <FormApplicationMob
        showForm={showForm}
        activeClass={activeClass}
         />
        {/* <ButtonOfApplication showForm={showForm} /> */}
        <FormVidjet 
        showFormVidjet={showFormVidjet}
        activeFormVidjet={activeFormVidjet}
        />
        <div className="application__button_1440">
                <div 
                onClick={showFormVidjet}
                className="application__containerButton application__button">
            {t("Submit your application")}
                </div>
            </div>
        </>
          
    )

}

export default ButtonsOfApplication;