import React from "react";
import { useTranslation } from 'react-i18next';

const ChoiceContact = ({
    activeChoiceContact, 
    showChoiceContact
}) => {
    // Хук библиотеки i18n меняет язык текста, записанного в public/locales/EN
    const { t } = useTranslation();
    return (
        <>
        <div className={activeChoiceContact}>
        <div className="formVidjet__background">
            <div className="choiceContact__container">
            <div className="choiceContact__cross">
                <img
                onClick={showChoiceContact}
                src="./images/cross.svg" alt=""></img>
            </div>
            <div className="choiceContact__message">
                <div className="choiceContact__containerMessage">
                    <span className="choiceContact__text">
                    {t("Submit your application")}
                    <br></br>
                    {t("one communication method")}
                    </span>
                </div>
            </div>
            <div className="choiceContent__logoOfContact">
                <a
                href="tel:+79027100074"
                className="choiceContent__items">
                    <img width={47} src="./images/call.svg" alt=""></img>
                    <span className="choiceContent__textOfContact">
                    {t("Call")}
                    </span>
                </a>
                <a
                href="https://wa.me/79027100074" target="_blank" rel="noopener"
                className="choiceContent__items">   
                    <img width={47} src="./images/Viber.svg" alt=""></img>
                    <span className="choiceContent__textOfContact">
                    Whats App
                    </span>
                </a>
            </div> 
        </div> 
        </div>
        </div>
        </>
        
               
    )
}

export default ChoiceContact;