import React, { useState } from "react";
import data from "./data";

const LanguageSelection = ({ 
    onChangeLanguage}) => {
    const [value, setValue] = useState('');
 
    const options = data.map((item) => {
       return <option key={item.id} value={item.lang}>{item.lang}</option>;
    });
    return <div className="mainView__containerLanguageSelect">
       <select className="mainView__languageSelect" value={value} onChange={(e) => {setValue(e.target.value); onChangeLanguage(e.target.value);}}>
          {options}
       </select>
    </div>
}


export default LanguageSelection;