import React from "react";
import { useTranslation } from 'react-i18next';

const SecondTitleFormApplication = () => {
    // Хук библиотеки i18n меняет язык текста, записанного в public/locales/EN
    const { t } = useTranslation();

    return (
        <>
        <span className="formApplication__secondTitle">
            {t("ApplicationLatest")}
            <br></br>
            {t("ApplicationLatest2")}
        </span>
        <span className="formApplication__secondTitle_paragraph">
            {t("ApplicationLatest3")}
            <br></br>
            {t("ApplicationLatest4")}
        </span>
        </>
        
    )

}

export default SecondTitleFormApplication;