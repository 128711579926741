import React from "react";
import { Link } from "react-router-dom";

const PersonalDataPolicy = () => {
    return ( 
        <>
        <div className="footer__rightContent">
            <div className="footer__containerRightContentTitle">
                <span className="footer__rightContentTitle_430">
                Политика в отношении обработки 
                <br></br>персональных данных
                </span>
            </div>
            <div className="footer__containerRightContentDate">
                <span className="footer__rightContentDate">
                16 августа 2023 года
                </span>
            </div>
            <div className="footer__containerRightContentText">
                <div className="footer__rightContentText">
                <span className="footer__rightContentTextThumbnail">
                    1. Общие положения
                </span>
                <br></br>
                1.1. Политика оператора в отношении обработки персональных данных (далее - Политика) разработана в целях обеспечения защиты прав и свобод субъекта персональных данных при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
                <br></br>
                1.2. Основные понятия, используемые в Политике:
                <br></br>
                1.2.1. Персональные данные - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных);
                <br></br>
                1.2.2. Обработка персональных данных - любое действие (операция) или совокупность действий (операций) с персональными данными, совершаемых с использованием средств автоматизации или без их использования. Обработка персональных данных включает в себя в том числе:
                <br></br>
                - сбор;
                <br></br>
                - запись;
                <br></br>
                - систематизацию;
                <br></br>
                - накопление;
                <br></br>
                - хранение;
                <br></br>
                - уточнение (обновление, изменение);
                <br></br>
                - извлечение;
                <br></br>
                - использование;
                <br></br>
                - передачу (распространение, предоставление, доступ);
                <br></br>
                - обезличивание;
                <br></br>
                - блокирование;
                <br></br>
                - удаление;
                <br></br>
                - уничтожение.
                <br></br>
                1.2.3. Автоматизированная обработка персональных данных - обработка персональных данных с помощью средств вычислительной техники;
                <br></br>
                1.2.4. Распространение персональных данных - действия, направленные на раскрытие персональных данных неопределенному кругу лиц;
                <br></br>
                1.2.5. Предоставление персональных данных - действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;
                <br></br>
                1.2.6. Блокирование персональных данных - временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);
                <br></br>
                1.2.7. Уничтожение персональных данных - действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных;
                <br></br>
                1.2.8. Обезличивание персональных данных - действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных;
                <br></br>
                1.2.9. Оператор персональных данных (оператор) - Общество с ограниченной ответственностью Группа Компаний «ПромПрофРезерв» (ООО ГК «ППР») (ОГРН 1236400006028, ИНН 6453173644, КПП 645301001, место нахождения: 410012, Саратовская обл., г. Саратов, ул. Им Вавилова Н.И., зд. 38/114, этаж 9).
                <br></br>
                1.3. Оператор, получивший доступ к персональным данным, обязан соблюдать конфиденциальность персональных данных - не раскрывать третьим лицам и не распространять персональные данные без согласия субъекта персональных данных, если иное не предусмотрено законодательством РФ.
                <br></br>
                1.4. Субъект персональных данных имеет право на получение информации, касающейся обработки его персональных данных, в том числе содержащей:
                <br></br>
                1) подтверждение факта обработки персональных данных оператором;
                <br></br>
                2) правовые основания и цели обработки персональных данных;
                <br></br>
                3) цели и применяемые оператором способы обработки персональных данных;
                <br></br>
                4) наименование и место нахождения оператора, сведения о лицах (за исключением работников оператора), которые имеют доступ к персональным данным или которым могут быть раскрыты персональные данные на основании договора с оператором или на основании законодательства РФ;
                <br></br>
                5) обрабатываемые персональные данные, относящиеся к соответствующему субъекту персональных данных, источник их получения, если иной порядок представления таких данных не предусмотрен законодательством РФ;
                <br></br>
                6) сроки обработки персональных данных, в том числе сроки их хранения;
                <br></br>
                7) порядок осуществления субъектом персональных данных прав, предусмотренных законодательством РФ;
                <br></br>
                8) информацию об осуществленной или о предполагаемой трансграничной передаче данных;
                <br></br>
                9) наименование или фамилию, имя, отчество, лица, осуществляющего обработку персональных данных по поручению оператора, если обработка поручена или будет поручена такому лицу;
                <br></br>
                10) иные сведения, предусмотренные Федеральным законом "О персональных данных" или другими федеральными законами.
                <br></br>
                1.5. Субъект персональных данных вправе требовать от оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав.
                <br></br>
                1.6. Субъект персональных данных имеет право на защиту своих прав и законных интересов, в том числе на возмещение убытков и (или) компенсацию морального вреда в судебном порядке.
                <br></br>
                1.7. Оператор персональных данных вправе:
                <br></br>
                - отстаивать свои интересы в суде;
                <br></br>
                - предоставлять персональные данные субъектов третьим лицам, если это предусмотрено действующим законодательством (налоговые, правоохранительные органы и др.);
                <br></br>
                - отказывать в предоставлении персональных данных в случаях, предусмотренных законодательством;
                <br></br>
                - использовать персональные данные субъекта без его согласия в случаях, предусмотренных законодательством.
                <br></br>
                1.8. При сборе персональных данных оператор обязан предоставить субъекту персональных данных по его просьбе информацию, предусмотренную частью 7 статьи 14 Федерального закона "О персональных данных".
                <br></br>
                1.9. При сборе персональных данных, в том числе посредством информационно-телекоммуникационной сети "Интернет", оператор обязан обеспечить запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных граждан Российской Федерации с использованием баз данных, находящихся на территории Российской Федерации, за исключением случаев, указанных в пунктах 2, 3, 4, 8 части 1 статьи 6 Федерального закона "О персональных данных".
                <br></br>
                1.10. Правовым основанием обработки персональных данных являются:
                <br></br>
                - совокупность правовых актов, во исполнение которых и в соответствии с которыми оператор осуществляет обработку персональных данных: Конституция Российской Федерации, Федеральный закон от 27.07.2006 г. № 152-ФЗ «О персональных данных», Федеральный закон от 27.07.2006 № 149-ФЗ «Об информации, информационных технологиях и о защите информации», Постановление Правительства РФ от 01.11.2012 № 1119 «Об утверждении требований к защите персональных данных при их обработке в информационных системах персональных данных».
                <br></br>
                <br></br>
                <span className="footer__rightContentTextThumbnail">    
                2. Цели сбора персональных данных
                </span>
                <br></br>    
                2.1. Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей. Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных.
                <br></br>    
                2.2. Цели обработки персональных данных происходят в том числе из анализа правовых актов, регламентирующих деятельность оператора, целей фактически осуществляемой оператором деятельности, а также деятельности, которая предусмотрена учредительными документами оператора, и конкретных бизнес-процессов оператора в конкретных информационных системах персональных данных (по структурным подразделениям оператора и их процедурам в отношении определенных категорий субъектов персональных данных).
                <br></br>    
                2.3. К целям обработки персональных данных оператора относятся:
                <br></br>    
                - направление на указанный Субъектом персональных данных адрес электронной почты сообщений, уведомлений, запросов, сведений информационного характера;
                <br></br>    
                - проведение, при необходимости, исследования любых категорий, в том числе анализа по улучшению качества Сайта;
                <br></br>    
                - обеспечение работоспособности и безопасности Сайта.
                <br></br>    
                <br></br> 
                <span className="footer__rightContentTextThumbnail">   
                3. Объем и категории обрабатываемых персональных данных, категории субъектов персональных данных
                </span>
                                    <br></br>    
                3.1. Содержание и объем обрабатываемых персональных данных соответствуют заявленным целям обработки. Обрабатываемые персональные данные не должны быть избыточными по отношению к заявленным целям их обработки.
                                    <br></br>    
                3.2. Обработка персональных данных допускается в следующих случаях:
                                    <br></br>    
                - обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его персональных данных;
                                    <br></br>    
                - обработка персональных данных необходима для исполнения договора, стороной которого либо выгодоприобретателем или поручителем по которому является субъект персональных данных, а также для заключения договора по инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться выгодоприобретателем или поручителем;
                                    <br></br>    
                - обработка персональных данных необходима для защиты жизни, здоровья или иных жизненно важных интересов субъекта персональных данных, если получение согласия субъекта персональных данных невозможно;
                                    <br></br>    
                - обработка персональных данных осуществляется в статистических или иных исследовательских целях, за исключением целей, указанных в статье 15 Федерального закона "О персональных данных", при условии обязательного обезличивания персональных данных;
                                    <br></br>    
                - осуществляется обработка персональных данных, подлежащих опубликованию или обязательному раскрытию в соответствии с федеральным законом.
                                    <br></br>  
                3.3. К категориям субъектов персональных данных относятся:
                                    <br></br>    
                3.3.1. Физические лица.
                                    <br></br>    
                В данной категории субъектов оператором обрабатываются персональные данные в связи с реализацией трудовых отношений:
                                    <br></br>    
                - фамилия, имя, отчество;
                                    <br></br>    
                - пол;
                                    <br></br>    
                - гражданство;
                                    <br></br>    
                - национальность;
                                    <br></br>    
                - дата (число, месяц, год) и место рождения (страна, республика, край, область, район, город, поселок, деревня, иной населенный пункт);
                                    <br></br>    
                - адрес места проживания (почтовый индекс, страна, республика, край, область, район, город, поселок, деревня, иной населенный пункт, улица, дом, корпус, квартира);
                                    <br></br>    
                - сведения о регистрации по месту жительства или пребывания (почтовый индекс, страна, республика, край, область, район, город, поселок, деревня, иной населенный пункт, улица, дом, корпус, квартира);
                                    <br></br>    
                - номера телефонов (домашний, мобильный, рабочий), адрес электронной почты;
                                    <br></br>    
                - замещаемая должность;
                                    <br></br>    
                - сведения о трудовой деятельности (наименования организаций (органов) и занимаемых должностей, продолжительность работы (службы) в этих организациях (органах));
                                    <br></br>    
                - идентификационный номер налогоплательщика (дата (число, месяц, год) и место постановки на учет, дата (число, месяц, год) выдачи свидетельства);
                                    <br></br>    
                - данные документа, подтверждающего регистрацию в системе индивидуального (персонифицированного) учета, в том числе в форме электронного документа;
                                    <br></br>    
                - данные полиса обязательного медицинского страхования;
                                    <br></br>    
                - данные паспорта или иного удостоверяющего личность документа;
                                    <br></br>    
                - данные паспорта, удостоверяющего личность гражданина Российской Федерации за пределами территории Российской Федерации;
                                    <br></br>    
                - данные трудовой книжки, вкладыша в трудовую книжку;
                                    <br></br>    
                - сведения о воинском учете (серия, номер, дата (число, месяц, год) выдачи, наименование органа, выдавшего военный билет, военно-учетная специальность, воинское звание, данные о принятии/снятии на (с) учет(а), о прохождении военной службы, о пребывании в запасе, о медицинском освидетельствовании и прививках);
                                    <br></br>    
                - сведения об образовании (наименование образовательной организации, дата (число, месяц, год) окончания, специальность и квалификация, ученая степень, звание, реквизиты документа об образовании и о квалификации);
                                    <br></br>    
                - сведения о получении дополнительного профессионального образования (дата (число, месяц, год), место, программа, реквизиты документов, выданных по результатам);
                                    <br></br>    
                - сведения о владении иностранными языками (иностранный язык, уровень владения);
                                    <br></br>    
                - сведения о судимости (наличие (отсутствие) судимости, дата (число, месяц, год) привлечения к уголовной ответственности (снятия или погашения судимости), статья);
                                    <br></br>    
                - сведения о дееспособности (реквизиты документа, устанавливающие опеку (попечительство), основания ограничения в дееспособности, реквизиты решения суда);
                                    <br></br>    
                - сведения об участии в управлении хозяйствующим субъектом (за исключением жилищного, жилищно-строительного, гаражного кооперативов, садоводческого, огороднического, дачного потребительских кооперативов, товарищества собственников недвижимости и профсоюза, зарегистрированного в установленном порядке), занятии предпринимательской деятельностью;
                                    <br></br>    
                - сведения, содержащиеся в медицинском заключении установленной формы об отсутствии у гражданина заболевания, препятствующего поступлению на гражданскую службу или ее прохождению (наличие (отсутствие) заболевания, форма заболевания);
                                    <br></br>    
                - сведения о наградах, иных поощрениях и знаках отличия (название награды, поощрения, знака отличия, дата (число, месяц, год) присвоения, реквизиты документа о награждении или поощрении);
                                    <br></br>    
                - сведения о дисциплинарных взысканиях;
                                    <br></br>    
                - сведения, содержащиеся в материалах служебных проверок;
                                    <br></br>    
                - сведения о семейном положении (состояние в браке (холост (не замужем), женат (замужем), повторно женат (замужем), разведен(а), вдовец (вдова), с какого времени в браке, с какого времени в разводе, количество браков, состав семьи, реквизиты свидетельства о заключении брака);
                                    <br></br>    
                - сведения о близких родственниках, свойственниках (степень родства, фамилия, имя, отчество, дата (число, месяц, год) и место рождения, место и адрес работы (службы), адрес места жительства, сведения о регистрации по месту жительства или пребывания);
                                    <br></br>    
                - сведения, содержащиеся в справках о доходах, расходах, об имуществе и обязательствах имущественного характера;
                                    <br></br>    
                - номер расчетного счета;
                                    <br></br>    
                - информация об оформленных допусках к государственной тайне;
                                    <br></br>    
                - фотографии;
                                    <br></br>    
                3.4. Иная информация, обрабатываемая Оператором персональных данных:
                                    <br></br>    
                - сведения об использовании Сайта;
                                    <br></br>    
                - информация, автоматически получаемая при доступе к Сайту, в том числе с использованием cookies;
                                    <br></br>    
                - информация, полученная в результате действий Субъекта персональных данных, в том числе следующие сведения: о направленных запросах, мнениях, отзывах и вопросах.
                <br></br>    

                                    <br></br> 
                <span className="footer__rightContentTextThumbnail">   
                    4. Порядок и условия обработки персональных данных
                </span>
                                    <br></br>    
                4.1. Оператор осуществляет обработку персональных данных - операции, совершаемые с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
                                    <br></br>    
                4.2. Обработка персональных данных осуществляется с соблюдением принципов и правил, предусмотренных Федеральным законом "О персональных данных".
                                    <br></br>    
                4.3. Обработка персональных данных оператором ограничивается достижением конкретных, заранее определенных и законных целей. Обработке подлежат только персональные данные, которые отвечают целям их обработки. Содержание и объем обрабатываемых персональных данных должны соответствовать заявленным целям обработки.
                                    <br></br>    
                4.4. Хранение персональных данных должно осуществляться в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных. Обрабатываемые персональные данные подлежат уничтожению либо обезличиванию по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.
                                    <br></br>    
                4.5. При осуществлении хранения персональных данных оператор персональных данных обязан использовать базы данных, находящиеся на территории Российской Федерации, в соответствии с ч. 5 ст. 18 Федерального закона "О персональных данных".
                                    <br></br>    
                4.6. Условием прекращения обработки персональных данных может являться достижение целей обработки персональных данных, истечение срока действия согласия или отзыв согласия субъекта персональных данных на обработку его персональных данных, а также выявление неправомерной обработки персональных данных.
                                    <br></br>    
                4.7. Оператор вправе поручить обработку персональных данных другому лицу на основании заключаемого с этим лицом договора, в том числе государственного или муниципального контракта.
                                    <br></br>    
                Лицо, осуществляющее обработку персональных данных по поручению оператора, обязано соблюдать принципы и правила обработки персональных данных, предусмотренные Федеральным законом "О персональных данных".
                                    <br></br>    
                Кроме того, оператор вправе передавать персональные данные органам дознания и следствия, иным уполномоченным органам по основаниям, предусмотренным действующим законодательством Российской Федерации.
                                    <br></br>    
                4.8. Оператор и иные лица, получившие доступ к персональным данным, обязаны не раскрывать третьим лицам и не распространять персональные данные без согласия субъекта персональных данных, если иное не предусмотрено действующим законодательством.
                                    <br></br>    
                Согласие на обработку персональных данных, разрешенных субъектом персональных данных для распространения, оформляется отдельно от иных согласий субъекта персональных данных на обработку его персональных данных. Оператор обязан обеспечить субъекту персональных данных возможность определить перечень персональных данных по каждой категории персональных данных, указанной в согласии на обработку персональных данных, разрешенных субъектом персональных данных для распространения.
                                    <br></br>    
                Передача (распространение, предоставление, доступ) персональных данных, разрешенных субъектом персональных данных для распространения, должна быть прекращена в любое время по требованию субъекта персональных данных. Данное требование должно включать в себя фамилию, имя, отчество (при наличии), контактную информацию (номер телефона, адрес электронной почты или почтовый адрес) субъекта персональных данных, а также перечень персональных данных, обработка которых подлежит прекращению. Указанные в данном требовании персональные данные могут обрабатываться только оператором, которому оно направлено.
                                    <br></br>    
                4.9. Оператор обязан принимать меры, необходимые и достаточные для обеспечения выполнения обязанностей, предусмотренных Федеральным законом "О персональных данных" и принятыми в соответствии с ним нормативными правовыми актами. Состав и перечень мер оператор определяет самостоятельно.
                                    <br></br>    
                4.10. Оператор при обработке персональных данных принимает необходимые правовые, организационные и технические меры или обеспечивает их принятие для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных.
                <br></br>    

                                    <br></br>
                <span className="footer__rightContentTextThumbnail">    
                    5. Права Субъекта персональных данных
                </span>
                                    <br></br>    
                5.1. Субъект персональных данных имеет право на получение информации об обработке персональных данных, в том числе содержащей:
                                    <br></br>    
                ● подтверждение факта обработки персональных данных;
                                    <br></br>    
                ● правовые основания обработки персональных данных;
                                    <br></br>    
                ● цели и применяемые Оператором персональных данных способы обработки персональных данных;
                                    <br></br>    
                ● обрабатываемые персональные данные, относящиеся к соответствующему
                <br></br>    

                                    <br></br>    
                    Субъекту персональных данных, и источник их получения, если иной порядок представления таких данных не предусмотрен законодательством Российской Федерации;
                                    <br></br>    
                ● сроки обработки персональных данных, в том числе сроки их хранения;
                                    <br></br>    
                ● порядок осуществления прав, предусмотренных законодательством Российской Федерации;
                                    <br></br>    
                ● информацию об осуществленной или о предполагаемой трансграничной передаче данных;
                                    <br></br>    
                ● сведения о лицах, которым могут быть раскрыты персональные данные на основании договора с Администратором или в соответствии с законодательством Российской Федерации;
                                    <br></br>    
                ● наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку персональных данных по поручению Администратора, если обработка поручена или будет поручена такому лицу;
                                    <br></br>    
                ● иные сведения, предусмотренные законодательством Российской Федерации.
                                    <br></br>    
                5.2. Субъект персональных данных вправе получать сведения, указанные в п. 6.1. Политики, неограниченное количество раз, направив Оператору персональных данных соответствующий запрос в порядке, предусмотренном разделом 11 Политики.
                <br></br>    

                                    <br></br>  
                <span className="footer__rightContentTextThumbnail">  
                    6. Обязанности Оператора персональных данных
                </span>
                                    <br></br>    
                6.1. В соответствии с требованиями Закона Оператор персональных данных обязан:
                                    <br></br>    
                ● предоставлять по запросу Субъекта персональных данных информацию об обработке его персональных данных, указанную в п. 6.1. Политики, или обоснованный отказ;
                                    <br></br>    
                ● принимать меры, необходимые и достаточные для обеспечения выполнения обязанностей, предусмотренных Законом;
                                    <br></br>    
                ● по требованию Субъекта персональных данных уточнять обрабатываемые персональные данные, блокировать или удалять, если они являются неполными, устаревшими, неточными, незаконно полученными или ненужными для заявленной цели обработки;
                                    <br></br>    
                ● обеспечить правомерность обработки персональных данных. В случае, если обеспечить правомерность обработки персональных данных невозможно, Оператор персональных данных в срок, не превышающий 10 (Десять) рабочих дней с даты выявления неправомерной обработки персональных данных, обязан уничтожить такие персональные данные или обеспечить их уничтожение;
                                    <br></br>    
                ● в случае отзыва Субъектом персональных данных согласия на обработку персональных данных прекратить их обработку и уничтожить в срок, не превышающий 30 (Тридцать) рабочих дней с даты поступления указанного отзыва, за исключением случаев, когда обработка может быть продолжена в соответствии с законодательством.
                <br></br>    

                                    <br></br>    
                <span className="footer__rightContentTextThumbnail">
                    7. Сведения о реализуемых требованиях к защите персональных данных
                </span>
                                    <br></br>    
                7.1. Защита персональных данных, обрабатываемых Оператором персональных данных обеспечивается реализацией правовых, организационных и технических мер, необходимых и достаточных для обеспечения требований законодательства в области защиты персональных данных.
                                    <br></br>    
                7.2. Правовые меры включают в себя:
                                    <br></br>    
                ● разработку локальных актов Оператора персональных данных, реализующих требования российского законодательства (в том числе Политики в отношении обработки и защиты персональных данных с размещением ее на Сайте);
                                    <br></br>    
                ● отказ от любых способов обработки персональных данных, не соответствующих заранее определенным Оператором персональных данных целям.
                                    <br></br>    
                7.3. Организационные меры включают в себя:
                                    <br></br>    
                ● назначение лица, ответственного за организацию обработки персональных данных;
                                    <br></br>    
                ● ограничение состава работников Оператора персональных данных, имеющих доступ к персональным данным, и организацию разрешительной системы доступа к персональным данным;
                                    <br></br>    
                ● ознакомление работников Оператора персональных данных с положениями законодательства Российской Федерации о персональных данных, в том числе с требованиями к защите персональных данных, с локальными актами оператора персональных данных по вопросам обработки персональных данных, обучение указанных работников.
                                    <br></br>    
                7.4. Технические меры включают в себя:
                                    <br></br>    
                ● идентификация и аутентификация субъектов доступа;
                                    <br></br>    
                ● применение средств криптографической защиты к информации, содержащей персональные данные;
                                    <br></br>    
                ● обнаружение (предотвращение) вторжений.
                                    <br></br>    
                7.5. Оператор персональных данных обязуется и обязует третьих лиц, в случае передачи им права на обработку персональных данных Субъекта персональных данных, соблюдать режим конфиденциальности в отношении таких персональных данных и не использовать их без согласия Субъекта персональных данных за исключением случаев, предусмотренных Политикой.
                <br></br>    

                                    <br></br>  
                <span className="footer__rightContentTextThumbnail">  
                    8. Зарубежные Субъекты персональных данных
                </span>
                                    <br></br>    
                8.1. До начала осуществления трансграничной передачи персональных данных Оператор персональных данных обязан убедиться в том, что иностранным государством, на территорию которого осуществляется передача персональных данных, обеспечивается адекватная защита прав Субъекта персональных данных.
                                    <br></br>    
                8.2. Трансграничная передача персональных данных на территории иностранных государств, не обеспечивающих адекватной защиты прав субъектов персональных данных, может осуществляться в случаях:
                                    <br></br>    
                ● наличия согласия в письменной форме Субъекта персональных данных на трансграничную передачу его персональных данных;
                                    <br></br>    
                ● исполнения договора, стороной которого является Субъект персональных данных;
                                    <br></br>    
                ● защиты жизни, здоровья, иных жизненно важных интересов Субъекта персональных данных или других лиц при невозможности получения согласия в письменной форме Субъекта персональных данных.
                <br></br>    

                                    <br></br> 
                <span className="footer__rightContentTextThumbnail">   
                    9. Ограничение действия Политики
                </span>
                                    <br></br>    
                9.1. Действие Политики распространяется исключительно на Сайт и не применяется к другим интернет-ресурсам.
                                    <br></br>    
                9.2. Субъект персональных данных обязан разумно и ответственно подходить к размещению собственных персональных данных на Сайте в открытом доступе при оставлении отзывов и комментариев.
                                    <br></br>    
                9.3. Оператор персональных данных не несет ответственности за действия третьих лиц, получивших доступ к персональным данным Субъекта персональных данных по вине последнего.
                <br></br>    

                                    <br></br>   
                <span className="footer__rightContentTextThumbnail"> 
                    10. Обращения Субъекта персональных данных
                </span>
                                    <br></br>    
                10.1. Субъект персональных данных вправе направлять Оператору персональных данных свои запросы, в том числе запросы относительно использования их персональных данных:
                                    <br></br>    
                ● в форме электронного документа по адресу электронной почты: hr@gkppr.ru .
                                    <br></br>    
                10.2. Запрос должен содержать следующую информацию:
                                    <br></br>    
                ● номер основного документа, удостоверяющего личность Субъекта персональных данных;
                                    <br></br>    
                ● сведения о дате выдачи указанного документа и выдавшем его органе;
                                    <br></br>    
                ● сведения, подтверждающие участие Субъекта персональных данных в отношениях с Оператором персональных данных;
                                    <br></br>    
                ● подпись Субъекта персональных данных.
                                    <br></br>    
                10.3. Оператор персональных данных обязуется рассмотреть и направить ответ на поступивший запрос в течение 30 (тридцати) дней с момента поступления обращения.
                                    <br></br>    
                10.4. Вся корреспонденция, полученная Оператором персональных данных от Субъекта персональных данных (обращения в письменной или электронной форме), относится к информации ограниченного доступа и не разглашается без его письменного согласия.
                <br></br>    

                                    <br></br>    
                <span className="footer__rightContentTextThumbnail">
                    11. Изменения Политики
                </span>
                                    <br></br>    
                11.1. Оператор персональных данных вправе вносить изменения в Политику по мере необходимости. Обязательный пересмотр Политики проводится в случае существенных изменений международного или национального законодательства в сфере персональных данных. Вступление новой редакции Политики в силу происходит с момента ее размещения на Сайте.
                <br></br>    

                                    <br></br> 
                <span className="footer__rightContentTextThumbnail">   
                    12. Контактная информация и реквизиты
                </span>
                <br></br>    
                Оператора персональных данных
                                    <br></br>    
                Полное наименование - Общество с ограниченной ответственностью Группа Компаний «ПромПрофРезерв»
                                    <br></br>    
                Сокращенное наименование - ООО ГК «ППР»
                                    <br></br>    
                Юридический адрес: 410012, Саратовская область, город Саратов, ул. им. Вавилова Н.И., зд. 38/114, этаж 9
                                    <br></br>    
                Почтовый адрес: 410012, Саратовская область, город Саратов, ул. им. Вавилова Н.И., зд. 38/114, этаж 9
                                    <br></br>    
                ИНН 6453173644/ КПП 645301001
                                    <br></br>    
                ОРГН 1236400006028
                                    <br></br>    
                Тел. +7 (902) 710 00-74
                                    <br></br>    
                Email: info@gkppr.ru
                </div>
            </div>
            <Link to="/" className="footer__containerRightContentTitle">{`${'< Назад'}`}</Link>
        </div>
        </>
                
    )
}

export default PersonalDataPolicy;