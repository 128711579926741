import React from "react";
import { useTranslation } from 'react-i18next';

const InfoAboutVacation = () => {
    const { t } = useTranslation();

    return (
        <div className="about__iconsItem">
            <img src="./images/vacation.svg" alt=""></img>
            <div className="about__iconsText">
                <span className="about__iconsTitle">
                {t("Vacancies inHeader")}
                </span>
                <span className="about__iconsTextIte">
                {t("We guarantee the constant availability of jobs")}
                </span>
            </div>
        </div>
    )
}

export default InfoAboutVacation;