import React from "react";
import Form from "../FormApplication/Form";
import { useTranslation } from 'react-i18next';

const FormApplicationMob = ({
    showForm, 
    activeClass
}) => { 
    // Хук библиотеки i18n меняет язык текста, записанного в public/locales/EN
    const { t } = useTranslation();
    return (
        <div className={activeClass}>
            <div className="formVidjet__background">
                <div className="form-media__container_430">
                    <div className="form-media__title_430 container_430">
                        <div className="form-media__containerOfTitle_430">
                            <span className="form-media__textOfTitle_430">
                            {t("Please")}
                            <br></br>
                            {t("fill out the forms")}
                            </span>
                        </div>
                        <div className="form-media__cross_430px">
                            <img
                            onClick={showForm}
                            src="./images/cross.svg" alt=""></img>
                        </div>  
                    </div>
                    <div className="form-media__formApplication_430">
                        <Form showForm={showForm} />
                    </div>
                </div>
            </div>
        </div>        
    )

}

export default FormApplicationMob;