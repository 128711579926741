import React from "react";
import { useTranslation } from 'react-i18next';

const InfoAboutCareer = () => {
    const { t } = useTranslation();

    return (
        <div className="about__iconsItem">
            <img src="./images/grafic.svg" alt=""></img>
            <div className="about__iconsText">
                <span className="about__iconsTitle">
                {t("Career")}
                </span>
                <span className="about__iconsTextIte">
                {t("We improve the qualifications and increase the salary of employees")}
                </span>
            </div>
        </div>
    )
}

export default InfoAboutCareer;