import React from "react";

import MainView from "./MainView/index.jsx";
import About from "./About/Index.jsx";
import Services from "./Services/Index";
import Benefits from "./Benefits/Index.jsx";
import Vacations from "./Vacations/Index.jsx";
import FormApplication from "./FormApplication/index.jsx";
import Footer from "./Footer/index.jsx";


const MainPage = () => {
    return (
    <>
      <header>
      </header>
      <main>
          <MainView />
          <About /> 
          <Services />
          <Benefits />
          <Vacations />
          <FormApplication />
          <Footer />
      </main>
    </>
  );
}

export default MainPage;
