import React from "react";
import { useTranslation } from 'react-i18next';

const InfoAbout = () => {
    // Хук библиотеки i18n меняет язык текста, записанного в public/locales/EN
    const { t } = useTranslation();
    return (
        <>
          <span className="about__text">
          {t("The PromProfReserve group of companies is a team of specialists with over 20 years of experience.")}
        </span>
        <span className="about__text_430">
        {t("team of specialists")}
            <br></br>
            {t("with more than 20 years of experience")}
        </span>
        <span className="about__textSecond">
            {t("We know that there are a lot of unscrupulous recruitment agencies on the market now, our goal is to really help people in finding a job and provide enterprises with diversified specialists.")}
        </span>
        
        </>
      
    )

}

export default InfoAbout;