import React from "react";
import { useState } from "react";
import { useTranslation } from 'react-i18next';

const ButtonOfContact = () => {
    // Хук библиотеки i18n меняет язык текста, записанного в public/locales/EN
    const { t } = useTranslation();
    
    const [isHoverCallBtn, setisHoverCallBtn] = useState('callWhite');

    // Функции onChangeIconCall изменяет цвет кнопки, производя замену состояния isHoverCallBtn при наведении курсора
    const onChangeIconCall = () => {
        setisHoverCallBtn("called")
    }
      // Функции onChangeIconCalled изменяют цвет кнопки, производя замену состояний isHoverCallBtn при покидании области разметки изображения
    const onChangeIconCalled = () => {
        setisHoverCallBtn("callWhite")
    }

    return (
        <div
        onMouseEnter={() => onChangeIconCall()} 
        onMouseLeave={() => onChangeIconCalled()} 
        >
            <a className="vacation__buttonOfContact" href="tel:+79027100074">
            <img
            // className="vacation__buttonOfContact_animate"
            width={30.22} height={30.22} src={`./images/${isHoverCallBtn}.svg`} alt=""></img>
            {t("Contact")}
            </a>
        </div>
    )

}

export default ButtonOfContact;