import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Rights = () => {
    const { t } = useTranslation();

    return (
        <>
            <span className="footer__copyRight">
            {t("Labor safety policy")}
            </span>
            <span className="footer__copyRight">
            {t("Terms of use")}
            </span>
            <Link to="/PersonalDataPolicy" className="footer__copyRight footer__copyRight_actived">
            {t("Policy regarding the processing of personal data")}
            </Link>
        </>
    )
}

export default Rights;
