import React from "react";
import "../src/styles/index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./i18n";

import MainPage from "./components/pages/main/index.jsx";
import PersonalDataPolicy from "./components/pages/main/PersonalDataPolicy";
import SnakeGamePage from "./components/pages/main/SnakeGamePage.jsx";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />}/>
        <Route path="/PersonalDataPolicy" element={<PersonalDataPolicy />}/>
        <Route path="/SnakeGamePage" element={<SnakeGamePage />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
