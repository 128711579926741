import React from "react";
import { useTranslation } from 'react-i18next';


const ButtonOfHeader = () => {
    // Хук библиотеки i18n меняет язык текста, записанного в public/locales/EN
    const { t } = useTranslation();
    return (
        <a href="#vacation" className="header__containerOfButton">
            <div className="header__button">
            {t("Vacancies inHeader")}
            </div>
        </a>
    )

}

export default ButtonOfHeader;