import React from "react";
import { useTranslation } from 'react-i18next';

const WorkingHours = () => {
    const { t } = useTranslation();

    return ( 
        <>
        <div className="footer__navContainer_hours">
            <div>
                <span className="footer__titleText">
                {t("Operating mode")}
                </span>
            </div>
            <div className="footer__nav footer__workingHours">
                <span className="footer__text_withoutDecoration">
                {t("Mon-Fri")}
                </span>
                <span className="footer__text_withoutDecoration">
                {t("from 09:00 to 18:00")}
                </span>
            </div>
        </div> 
        </>
                
    )
}

export default WorkingHours;