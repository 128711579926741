import React from "react";
import ButtonOfMainView from "./ButtonsOfMainView";
import { useTranslation } from 'react-i18next';
import TitlePageSecond from "./TitlePageSecond";

const TitlePage = () => {
    // Хук библиотеки i18n меняет язык текста, записанного в public/locales/EN
    const { t } = useTranslation();

    return (
        <>
            <h1 className="mainPrint__name">
            {t("PROVISION OF STAFF/")}
                <br></br>
                {t("PERFORMANCE")}
                <br></br> 
                {t("CONSTRUCTION WORKS")}
            </h1>
            
            <div className="mainPrint__containerName430px">
                <div className="mainPrint__name430px">
                    <h1 className="mainPrint__name430px_h1">
                    {t("PROVISION OF STAFF/")}
                    </h1>
                    <div className="mainPrint__name430px_containerSpan">
                        <span className="mainPrint__name430px_span">
                        {t("PERFORMANCE")}
                        </span>
                        <span className="mainPrint__name430px_span2">
                        {t("CONSTRUCTION WORKS")}
                        </span>
                    </div>
                </div>
                <TitlePageSecond />
                <ButtonOfMainView />
            </div>
            

        </>
       
    )

}

export default TitlePage;