import React from "react";
import { useTranslation } from 'react-i18next';

const KeywordsOfApplication = () => {
    const { t } = useTranslation();

    return (
        <>
        <div className="apolication__containerKeywords">
            <div className="application__keywords">
                <span className="application__keyword">
                {t("electrician")}
                    </span>
                <span className="application__keyword">
                {t("Driver B, C, D, E")}
                    </span>
                <span className="application__keyword">
                {t("TT installer")}
                    </span>
                <span className="application__keyword">
                {t("Objects")}
                    </span>
            </div>
            <div className="application__keywords">
                <span className="application__keyword">
                {t("Handyman")}
                    </span>
                <span className="application__keyword">
                {t("Engineering and technical worker")}
                    </span>
                <span className="application__keyword">
                {t("Locksmith KCHPiA")}
                    </span>
            </div>
            <div className="application__keywords">
                <span className="application__keyword">
                {t("ST and JBK installer")}
                    </span>
                <span className="application__keyword">
                {t("concrete worker")}

                    </span>
                <span className="application__keyword">
                {t("Fitter")}
                    </span>
                <span className="application__keyword">
                {t("Welder RD/RAD")}
                    </span>
            </div>
        </div>
    </>
    )

}

export default KeywordsOfApplication;