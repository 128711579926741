import React from "react";
import Accreditation from "./Accreditation";
import { useTranslation } from 'react-i18next';

const LogoInfo = () => {
    // Хук библиотеки i18n меняет язык текста, записанного в public/locales/EN
    const { t } = useTranslation();

    return (
        <div className="header__logo">
            <div className="header__info">
                <img width={74} height={31} className="header__logoGrey" src="./images/logo_PromProfReservegrey_svg.svg" alt="Логотип"/>
                <div className="header__separator"></div>
                <div className="header__titleContainer">
                    <span className="header__titleOfAccreditation">
                    {t("LLC Group of Companies")}
                    <br></br>
                    {t("PromProfReserve")}
                    </span>
                </div>
            </div>
            <div className="header__containerAccreditation">
                <Accreditation />
            </div>
        </div>
    )

}

export default LogoInfo;