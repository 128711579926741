import React from "react";
import { useTranslation } from 'react-i18next';

const Tel = ({
    contact,
    changeContact, 
    handleFocus, 
    contactValid,
    emailValid,
    isPressAddCommentBtn,
}) => {
    const { t } = useTranslation();

    return (
        <div className="formApplication__containerBorder">
            <input
            // autocomplete="off"
            name="phone"
            className="formApplication__border"
            onInput={(e) => {changeContact(e);
                //  handleFocus("contact")}
                }}
            onBlur={(e) => {
                changeContact(e); 
                // handleFocus("contact")
            }}
            // onChange={(e) => changeContact(e)}
            // onFocus={(e) => changeContact(e)}
            onFocus={(e) => handleFocus("contact")}
            value={contact}
            type="tel" 
            placeholder=
            {t("Telephone")}
            // required
            />
            <div>  
                <div
                className={
                isPressAddCommentBtn
                ? (contactValid.isValid 
                    // && (contactValid.required || emailValid.required) 
                    ? "formApplication__error_okContact" 
                    : "formApplication__error_noContact") 
                : ""
                }>
                {isPressAddCommentBtn && 
                (contactValid && contactValid.isValid 
                // && (contactValid.required || emailValid.required)
                 ? "" : contactValid.content)}
                </div>
            </div> 
        </div>
    )
}

export default Tel;