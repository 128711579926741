import React from "react";
import { useTranslation } from 'react-i18next';

const TitlePageBenefits = () => {
    const { t } = useTranslation();

    return (
        <h2 className="mainPrint__title">
            {t("ADVANTAGES")}
        </h2>
    )

}

export default TitlePageBenefits;