import React from "react";
import { useTranslation } from 'react-i18next';

const ButtonOfApplication = ({
    showForm, 
}) => { 
    const { t } = useTranslation();
    return (
        <div className="application__button_430">
            <div
            onClick={showForm}
            className="application__containerButton application__button">
            {t("Submit your application")}
            </div>
        </div>
    )

}

export default ButtonOfApplication;