import React from "react";
import { useTranslation } from 'react-i18next';

const TitlePageFormApplication = () => {
    // Хук библиотеки i18n меняет язык текста, записанного в public/locales/EN
    const { t } = useTranslation();
    return (
        <h2 className="formApplication__mainTitle">
            {t("Remained")}
            <br></br> 
            {t("QUESTIONS?")}
        </h2>
    )

}

export default TitlePageFormApplication;