import React, { useState } from "react";
import LogoInfo from "./LogoInfo";
import Breadcrumbs from "./Breadcrumbs";
import "../../../../styles/index.css";
import "../../../../styles/Header.css";
import LanguageSelection from "../MainView/LanguageButons/LanguageSelection";
import { useTranslation } from 'react-i18next';

const Header = () => {
    const [activeClassHeader, setActiveClassHeader] = useState("header-media_hide");

    // Функции showHeader выводит блок Header в мобильной версии при нажатии изображения "Бургера", производя замену состояния activeClassHeader
    const showHeader = () => {
        setActiveClassHeader(
            (prevActiveClassHeader) => prevActiveClassHeader === "header-media_hide" 
            ? "header-media" : "header-media_hide"
        );
    }
    
    // Хук библиотеки i18n меняет язык страницы. Текст расположен в директории: public/locales/EN
    const { i18n } = useTranslation();
    // Функция onChangeLanguage производит смену языка страницы через библиотеку i18n
    const onChangeLanguage = (language) => {
        i18n.changeLanguage(language);}

    return (
        <>
            <div className="header">
                <LogoInfo />
                <div className="header__container">
                    <Breadcrumbs />
                </div>
            </div>
            <div
             className="header__container_430"
             >
                <Breadcrumbs 
                showHeader={showHeader} 
                activeClassHeader={activeClassHeader} 
                />
            </div>
            <div className="header430px">
                <img width={94} height={39} className="header__logo430px" src="./images/logo_PromProfReserve_svg.svg" alt="логотип"></img>
                <div className="header430px__buttons">
                    <LanguageSelection 
                    onChangeLanguage={onChangeLanguage}
                    />
                    <div onClick={showHeader}>    
                        <img className="header__burger" src="./images/burger.svg" alt=""></img>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Header;