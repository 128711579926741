import React from "react";
import ButtonOfHeader from "./ButtonOfHeader";
import LinksOfBradcrumbs from "./LinksOfBreadcrumbs";


const Breadcrumbs = ({showHeader, activeClassHeader}) => {
    return (
        <>
            <div className="header__breadcrumbs">
                <LinksOfBradcrumbs 
                showHeader={showHeader} 
                activeClassHeader={activeClassHeader} 
                />
                <ButtonOfHeader />
        </div>
        </>
        

        
    )

}

export default Breadcrumbs;