import React from "react";
import { useState } from "react";
import Accreditation from "../Header/Accreditation";
import LanguageButons from "./LanguageButons/LanguageButons";
import { useTranslation } from 'react-i18next';

const ButtonOfContact = () => {
    const [isPressCallBtn, setisPressCallBtn] = useState('CallMainView');
    const [isHoverCallBtn, setisHoverCallBtn] = useState('');
    const [activedLanguage, setActivedLanguage] = useState(1);

    // Функции onChangeColor, onChangeIconCall изменяют цвет кнопки, производя замену состояния isPressCallBtn, isHoverCallBtn при наведении курсора
    const onChangeColor = () => {
        setisHoverCallBtn("mainView__containerButtonOfContact_isHover")
    }
    const onChangeIconCall = () => {
        setisPressCallBtn("CalledMainView")
    }
    // Функции onChangeColorBack, onChangeIconCalled изменяют цвет кнопки, производя замену состояний isPressCallBtn, isHoverCallBtn при покидании области разметки изображения
    const onChangeColorBack = () => {
        setisHoverCallBtn("")
    }
    const onChangeIconCalled = () => {
        setisPressCallBtn("CallMainView")
    }

    // Хук библиотеки i18n меняет язык страницы. Текст расположен в директории: public/locales/EN
    const { i18n } = useTranslation();

    // Функция onChangeLanguage производит смену языка страницы через библиотеку i18n
    const onChangeLanguage = (language) => {
        i18n.changeLanguage(language);
    }

    // Функция handleClick изменяет стиль кнопки, выделяя активный язык страницы
    const handleClick = (e, id) => {
        setActivedLanguage(id);       
    }; 

    return (
        <div className="mainView__containerIcons">
            <div className="mainView__containerLanguage">
                <div className="mainView__Icons_1024">
                    <Accreditation />
                </div>
                <LanguageButons
                activedLanguage={activedLanguage} 
                handleClick={handleClick} 
                onChangeLanguage={onChangeLanguage}/>
            </div>
            <div className="mainView__contacts">
                <a
                href="tel:+79027100074"
                onMouseEnter={() => {onChangeColor(); onChangeIconCall()}} 
                onMouseLeave={() => {onChangeColorBack(); onChangeIconCalled()}}
                className={`mainView__containerButtonOfContact ${isHoverCallBtn}`}>
                    <div className="mainView__icons_430 mainView__buttonOfContact">
                        +7 (902) 710-00-74  
                    </div>
                    <img 
                    className="mainView__icons_430"
                    width={40} height={40} 
                    src={`./images/${isPressCallBtn}.svg`} 
                    alt=""></img>
                </a>
            </div>
        </div>
    )

}

export default ButtonOfContact;