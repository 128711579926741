import React from "react";
import { useTranslation } from 'react-i18next';

const InfoAboutPartnership = () => {
    const { t } = useTranslation();

    return (
        <div className="about__iconsItem">
        <img src="./images/partnership.svg" alt=""></img>
        <div className="about__iconsText">
            <span className="about__iconsTitle">
            {t("Cooperation")}
            </span>
            <span className="about__iconsTextItem">
            {t("Works with large")}
                <br></br>
                {t("industrial companies")}
            </span>
        </div>
        </div>
            
    )
}

export default InfoAboutPartnership;