import React from "react";
import ButtonOfContact from "./ButtonOfContact";
import { useTranslation } from 'react-i18next';

const Service = () => {
    // Хук библиотеки i18n меняет язык текста, записанного в pblic/locales/EN
    const { t } = useTranslation();

    return (
        <>
         <div className="services__frame">
                <div className="services__frame_430">
                    <h2 className=" services__title">
                    {t("Outstaffing")}
                        </h2>
                    <span className=" services__text">
                    {t("We provide the customer with specialists and undertake their legal support. Employees work for the customer, but are on our staff.")}
                    </span>
                </div>
                <ButtonOfContact />
            </div>
            <div className="services__frame">
                <div className="services__frame_430">
                    <h2 className=" services__title">
                    {t("Recruitment")}
                        </h2>
                    <span className=" services__text">
                    {t("Service for the search and hiring of specialists in the company's staff. We have an extensive database of candidates and an operational HR department. We are engaged in mass and point selection.")}
                    </span>
                </div>
                <ButtonOfContact />
            </div>
            <div className="services__frame">
                <div className="services__frame_430">
                    <h2 className=" services__title">
                    {t("Turnkey work")}
                        </h2>
                    <span className=" services__text">
                    {t("A full range of construction, installation and electrical work at any stage.")}
                    </span>
                </div>
                <ButtonOfContact />
            </div>
        </> 
    )

}

export default Service;