import React, { useState } from "react";
import ButtonOfContact from "./ButtonOfContact";
import { useTranslation } from 'react-i18next';

const Content = ({
    title, 
    title2, 
    title3, 
    img, 
    img_430, 
    img_1024, 
    salary, 
    kind, 
    buns,
    conditions, 
    city
}) => {
    // Хук библиотеки i18n меняет язык текста, записанного в public/locales/EN
    const { t } = useTranslation();

    const [activeClass, setActiveClass] = useState(false);
    const [activeClassMore, setActiveClassMore] = useState("vacation__more");

    // Функции showMore выводит "аккордеон" для мобильной версии при нажатии "Подробнее", производя замену состояния activeClassMore
    const showMore = () => {
        setActiveClassMore(
            (prevActiveClassMore) => prevActiveClassMore === "vacation__more" 
            ? "vacation__less" : "vacation__more"
        );
    }

    return (
        <>
        <div className="vacation">
            <div className="vacation__content">
                <img width={367} height={422} className="vacation__image" src={img} alt=""/>
                <img width={277} height={318} className="vacation__image_1024" src={img_1024} alt=""/>
                <div className="vacation__containerItem">
                    <div className="vacation__containerInfo">
                        <div className="vacation__title">
                            <span>{title}
                            {/* <br></br>{title2}<br></br> */}
                            </span>
                        </div>
                        <div className="vacation__info">
                            <span className="vacation__infoImg"> <img width={21} height={21} src="./images/ok_blue.svg" alt=""></img> {salary}</span>
                            <span className="vacation__infoImg"> <img width={21} height={21} src="./images/ok_blue.svg" alt=""></img> {kind}</span>
                            <span className="vacation__infoImg"> <img width={21} height={21} src="./images/ok_blue.svg" alt=""></img> {buns}</span>
                            <span className="vacation__infoImg"> <img width={21} height={21} src="./images/ok_blue.svg" alt=""></img> {conditions}</span>
                        </div>
                        <div className="vacation__location">
                            <span className="vacation__infoImg">
                                <img className="vacation__infoImg_1024" width={17} height={22} src="./images/place.svg" alt=""></img>
                                <img className="vacation__infoImg_1440" width={21} height={27} src="./images/place.svg" alt=""></img> {city}
                            </span>
                            <ButtonOfContact />
                        </div>
                    </div> 
                </div>
                   
            </div>   
        </div> 
        
        <div className="vacation_430">
            <div className="vacation__container_430">
                <div className="vacation__content_430">
                    <div className="vacation__title_430">
                        <span>{title}<br></br>{title2}<br></br>{title3}</span>
                        <span className="vacation__infoImg_430"> <img src="./images/place_white.svg" alt=""></img> {city}</span>
                    </div>
                    <img className="vacation__image_430" src={img_430} alt=""/>
                </div>
                <div 
                className="vacation__more_430">
                    <span
                    onClick={() => {setActiveClass(!activeClass); showMore()}}
                    >{t("More details")}</span>
                    <div
                    onClick={() => {setActiveClass(!activeClass); showMore()}}
                    className={
                        (activeClass) 
                        ?
                        "vacation__moreSymbolTrue" 
                        : 
                        "vacation__moreSymbolFalse"
                        }>
                       <svg width="5" height="11" viewBox="0 0 5 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 9.5L4 5.5L1 1.5" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
               
            </div>
            <div className={activeClassMore}>
                <div className="vacation__moreBack"></div>
                <div className="vacation__moreContent">
                    <div className="vacation__moreItem"> <img src="./images/ok.svg" alt=""></img> {salary}</div>
                    <div className="vacation__moreItem"> <img src="./images/ok.svg" alt=""></img> {kind}</div>
                    <div className="vacation__moreItem"> <img src="./images/ok.svg" alt=""></img> {buns}</div>
                    {/* <div className="vacation__moreItem"> <img src="./images/ok.svg" alt=""></img> {conditions}</div> */}
                </div>
            </div>
        </div>
        </>
    )

}

export default Content;