import React, { useState } from "react";
import TitlePageVacations from "./TitlePageVacations";
import Content from "./Content";
import ChoiceContact from "./ChoiceContact";
import { useTranslation } from 'react-i18next';

const Vacations = () => {
    // Хук библиотеки i18n меняет язык текста, записанного в public/locales/EN
    const { t } = useTranslation();
    const data = [
        {
            id: 1,
            img: "./images/vacation01.png",
            img_430: "./images/vacation01_430.png",
            img_1024: "./images/vacation01_1024.png",
            title: `${t("ST and JBK installer")}`,  
            title2: "",
            salary: `${t("Salary from")} 115 000 ₽/${t("months")}.`,
            kind: `${t("Watch")} 60/30`,
            buns: `${t("Expenses for transfer and meals at the expense of the company")}`,
            conditions: `${t("Comfortable living conditions")}`,
            city: `${t("location2")}`,
        },
        // {
        //     id: 2,
        //     img: "./images/vacation02.png",
        //     img_430: "./images/vacation02_430.png",
        //     img_1024: "./images/vacation02_1024.png",
        //     title: `${t("electrician")}`,  
        //     title2: ' ',
        //     salary: `${t("Salary from")} 105.000 ₽/${t("months")}.`,
        //     kind: `${t("Watch")} 60/30`,
        //     buns: `${t("Expenses for transfer and meals at the expense of the company")}`,
        //     conditions: `${t("Comfortable living conditions")}`,
        //     city: `${t("location1")}`,
        // },
         {
            id: 3,
            img: "./images/vacation05.png",
            img_430: "./images/vacation05_430.png",
            img_1024: "./images/vacation05_1024.png",
            title: `${t("electric welder")}`,  
            title2: '',
            salary: `${t("Salary from")} 125 000 ₽/${t("months")}.`,
            kind: `${t("Watch")} 60/30`,
            buns: `${t("Expenses for transfer and meals at the expense of the company")}`,
            conditions: `${t("Comfortable living conditions")}`,
            city: `${t("location2")}`,
        },
        {
            id: 4,
            img: "./images/vacation03.png",
            img_430: "./images/vacation03_430.png",
            img_1024: "./images/vacation03_1024.png",
            title: `${t("construction and installation work foreman")}`,  
            title2: "",
            salary: `${t("Salary from")} 125 000 ₽/${t("months")}.`,
            kind: `${t("Watch")} 60/30`,
            buns: `${t("Expenses for transfer and meals at the expense of the company")}`,
            conditions: `${t("Comfortable living conditions")}`,
            city: `${t("location2")}`,
        },
        {
            id: 5,
            img: "./images/vacation04.png",
            img_430: "./images/vacation04_430.png",
            img_1024: "./images/vacation04_1024.png",
            title: `${t("concrete worker")}`,  
            title2: "",
            salary: `${t("Salary from")} 115 000 ₽/${t("months")}.`,
            kind: `${t("Watch")} 60/30`,
            buns: `${t("Expenses for transfer and meals at the expense of the company")}`,
            conditions: `${t("Comfortable living conditions")}`,
            city: `${t("location2")}`,
        }
    ]
    const [activeChoiceContact, setActiveChoiceContact] = useState("choiceContact-media_hide");
    
    // Функции showChoiceContact выводит блок ChoiceContact при нажатии "Связаться", производя замену состояния activeChoiceContact
    const showChoiceContact = () => {
        setActiveChoiceContact((prevActiveChoiceContact) => 
            prevActiveChoiceContact === "choiceContact-media_hide" 
            ? "choiceContact-media" : "choiceContact-media_hide")}
    return (
        <div id="vacation" className="vacation430">
            <TitlePageVacations />
            {
                data.map((vacation) => (
                    <Content
                    key={vacation.id}
                    img={vacation.img} 
                    img_430={vacation.img_430} 
                    img_1024={vacation.img_1024} 
                    title={vacation.title} 
                    title2={vacation.title2} 
                    title3={vacation.title3} 
                    salary={vacation.salary} 
                    kind={vacation.kind} 
                    buns={vacation.buns} 
                    conditions={vacation.conditions}
                    city={vacation.city} 
                    />))
            }
            
            <div className="vacation__containerButton_430">
                <div 
                onClick={showChoiceContact}
                className="vacation__button_430">
                {t("Contact")}
                </div>
            </div>
            <div className="vacation__choiceContent">
                <ChoiceContact 
                showChoiceContact={showChoiceContact} 
                activeChoiceContact={activeChoiceContact} />
            </div>
            
        </div>
    )
}

export default Vacations;