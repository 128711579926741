import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

const TitlePageSecond = () => {
    // Хук библиотеки i18n меняет язык текста, записанного в public/locales/EN
    const { t } = useTranslation();

    return (
        <>
        <h2 className="mainPrint__second">
        {t("We help")}
            <span className="mainPrint__second_highlight">
            {t("clients")}
            </span> 
            {t("solve HR issues")}
            <br></br> 
            {t("We guarantee")}
            <Link to="/SnakeGamePage" className="mainPrint__second_highlight mainPrint__text_withoutDecoration">
            {t("for employees")}
            </Link> 
            {t("permanent employment")}
        </h2>

        <div className="mainPrint__container_430px">
            <div className="mainPrint__containerSecond_430px">
                <span className="mainPrint__second_430px">
                    {t("We help")}
                    <span className="mainPrint__second_highlight">
                    {t("clients")}
                    </span>
                    {t("solve HR issues")}
                    <br></br> 
                    {t("We guarantee")}
                    <Link to="/SnakeGamePage" className="mainPrint__second_highlight mainPrint__text_withoutDecoration">
                    {t("for employees")}
                    </Link> 
                    <span>
                    {t("permanent employment")}
                    </span>
                </span>
            </div>
            <div>
                <img className="mainPrint__image_430px" src="./images/mainView_mob_png.png" alt=""></img>
            </div>
        </div>
        </>
        
    )

}

export default TitlePageSecond;