import React from "react";
import { useTranslation } from 'react-i18next';

const InfoAboutTurnkey = () => {
    const { t } = useTranslation();
    return (
        <div className="about__iconsItem">
            <img src="./images/keys.svg" alt=""></img>
            <div className="about__iconsText">
                <span className="about__iconsTitle">
                {t("Turnkey work")}
                </span>
                <span className="about__iconsTextIte">
                {t("We act as contractors on construction sites")}
                </span>
            </div>
        </div>
    )
}

export default InfoAboutTurnkey;