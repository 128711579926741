import React from "react";

import TitlePageServices from "./TitlePageServices";
import Application from "../Application/Index";
import Service from "./Service";

const Services = () => {
    return (
        // По Id производится переход к блоку при нажатии ссылок элементов Breadcrumbs, расположенных в блоках Header и Footer
        <div id="services" className="services">
            <TitlePageServices />
            <div className="services__container">
                <div className="services__vidjets_430 services__vidjets">
                    <Service />
                </div>                 
                <div>
                    <Application />
                </div>
            </div>
            
        </div>
    )
}

export default Services;