import React from "react";
import { useTranslation } from 'react-i18next';

const Accreditation = () => {
    // Хук библиотеки i18n меняет язык текста, записанного в public/locales/EN
    const { t } = useTranslation();

    return (
        <div className="header__ContainerOfAccreditation">
            <img className="header__accreditation" src="./images/accreditation.svg" alt=""></img>
            <span className="header__titleOfAccreditation">
            {t("Accreditation by Rostrud")}
            <br></br>№2-23/64
            </span>
        </div>
    )

}

export default Accreditation;