import React from "react";
import { useTranslation } from 'react-i18next';

const ButtonOfTitle = () => {
    // Хук библиотеки i18n меняет язык текста, записанного в public/locales/EN
    const { t } = useTranslation();

    return (
        <div className="mainView__containerContactButtons">
            <div className="mainView__containerMainButton">
                <a href="#formApplication" className="mainView__button">
                {t("Submit your application")}
                </a> 
            </div>
            <div className="mainView__icons">
                <a href="https://dzen.ru/id/655477b43a2cd66a591509d1" target="_blank" rel="noopener">
                    <img width={40} height={40} src="./images/yandex-zen.svg" alt="Ссылка на яндекс-дзен"></img>
                </a>
                <a href="https://wa.me/79027100074" target="_blank" rel="noopener">
                    <img className="mainView__icons_430" width={40} src="./images/Viber.svg" alt=""></img>
                </a>
                <a href="https://vk.com/public.gr_ppr" target="_blank" rel="noopener">
                    <img className="mainView__icons_430" width={40} src="./images/vk.svg" alt="Ссылка на группу в вк"></img>
                </a>
            </div>
        </div>
                  
    )
}

export default ButtonOfTitle;