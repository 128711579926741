import React from "react";
import { useTranslation } from 'react-i18next';

const Email = ({
    email,
    emailValid,
    changeEmail,
    handleFocus,
    isPressAddCommentBtn,
}) => {
    const { t } = useTranslation();

    return (
        <div className="formApplication__containerBorder">
            <input
            // autocomplete="off"
            name="email"
            className="formApplication__border"
            type="email"
            onInput={(e) => {changeEmail(e);
                //  handleFocus("email")
                }}
            onBlur={(e) => {
                changeEmail(e)}
            }
            // onChange={(e) => changeEmail(e)}
            // onFocus={(e) => changeEmail(e)}
            onFocus={() => handleFocus("email")}
            value={email}  
            placeholder=
            {t("Email")}
            />
            <div>  
                <div
                className={
                isPressAddCommentBtn
                ? (emailValid.isValid
                    ? "formApplication__error_okEmail" 
                    : "formApplication__error_noEmail") 
                : ""
                }>
                {
                isPressAddCommentBtn && 
                (emailValid && emailValid.isValid ? "" : emailValid.content)}
                </div>
            </div> 
        </div>
    )
}

export default Email;