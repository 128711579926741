import React from "react";
import { useTranslation } from 'react-i18next';
import ButtonOfApplication from "./Buttons/ButtonOfApplication";

const InfoApplication = ({showForm}) => {
    const { t } = useTranslation();

    return (
        <div className="application__info">
            <span className="application__title">
            {t("Find out more about our services")}
            <br></br>
            {t("and get a unique commercial offer")}
            </span>
            <span className="application__text">
            {t("Our manager will contact you to clarify the details")}
            </span>
            <ButtonOfApplication showForm={showForm}/>
        </div>
    )

}

export default InfoApplication;