import React from "react";
import { Link } from "react-router-dom";
import SnakeGame from "./SnakeGame/Index";

const SnakeGamePage = () => {
    return ( 
        <>
    <SnakeGame />            
    <Link to="/" className="footer__containerRightContentTitle">{`${'< Назад'}`}</Link>
        </>  
    )  
}

export default SnakeGamePage;