import React from "react";
import { useTranslation } from 'react-i18next';

const ThankYouPage = ({ showThankYouPage }) => {
    // Хук библиотеки i18n меняет язык текста, записанного в public/locales/EN
    const { t } = useTranslation();

    return (
        <div className="formVidjet__background">
            <div className="thankYouPage__container">
                <div className="thankYouPage__cross">
                    <div className="">
                        <img
                        className="thankYouPage__crossImage"
                        onClick={showThankYouPage}
                        src="./images/cross.svg" alt=""></img>
                    </div>
                </div>
                <div className="thankYouPage__containerOk">
                    <div className="thankYouPage__ok">
                        <img src="./images/ok_form_430.svg" alt=""></img>
                    </div> 
                    <div className="thankYouPage__containerMessage">
                        <span className="thankYouPage__title">
                        {t("Thank you for your application!")}
                        </span>
                        <span className="thankYouPage__text">
                        {t("Our manager will contact you soon")}
                        </span>
                    </div>
                    <div className="thankYouPage__logoOfContact">
                        {/* <img width={47} src="./images/vk.svg" alt=""></img>
                        <img width={47} src="./images/Viber.svg" alt=""></img> */}
                        <a href="https://vk.com/public.gr_ppr" target="_blank" rel="noopener">
                            <img width={36} height={36} src="./images/vk.svg" alt="Ссылка на группу в вк"></img>
                        </a>
                        <a href="https://wa.me/79027100074" target="_blank" rel="noopener">
                            <img width={36} height={36} src="./images/Viber.svg" alt=""></img>
                        </a>
                        <a href="https://dzen.ru/id/655477b43a2cd66a591509d1" target="_blank" rel="noopener">
                            <img width={36} height={36} src="./images/yandex-zen.svg" alt="Ссылка на яндекс-дзен"></img>
                        </a>
                    </div> 
                </div>
            </div>
        </div>
        
    )
}

export default ThankYouPage;