import React from "react";
import { useTranslation } from 'react-i18next';

const Name = ({
    name, 
    changeName, 
    handleFocus, 
    nameValid, 
    isPressAddCommentBtn,
}) => {
    const { t } = useTranslation();

    return (
        <div className="formApplication__containerBorder">
            <input
            // autocomplete="off"
            name="name"
            className="formApplication__border"
            onInput={(e) => {changeName(e);
                //  handleFocus("name")
                }}
            onBlur={(e) => {
            // handleFocus("name"); 
            changeName(e)
        }
        }
            // eslint-disable-next-line no-undef
            onFocus={() => handleFocus("name")}        
            value={name} 
            type="text" 
            placeholder=
            {t("Name")}
            required
            />
            <div>  
                <div
                className={
                isPressAddCommentBtn
                ? (nameValid.isValid
                    ? "formApplication__error_okName" 
                    : "formApplication__error_noName") 
                : ""
                }>
                {isPressAddCommentBtn &&
                (
                nameValid &&
                 nameValid.isValid ? "" : nameValid.content)}
                </div>
            </div> 
        </div> 
    )
}

export default Name;
