import React from "react";
import { useTranslation } from 'react-i18next';

const Address = () => {
    const { t } = useTranslation();

    return ( 
        <>
        <div className="footer__navContainer_hide footer__navContainer_430">
            <span className="footer__titleText">
            {t("Address")}
            </span>
            <div className="footer__nav">
                <span className="footer__text_withoutDecoration">
                410012,
                {t("Saratov region")}
                <br></br> 
                {t("Saratov city, st. Vavilova N.I.")}
                {/* <br></br> 
                {t("building 38/114, floor 9")} */}
                </span>
            </div>
        </div> 
        </>
                
    )
}

export default Address;